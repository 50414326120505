import { provincesComputerProviders } from '../constants';
import { Language } from '../typings/Language';

export function getProvinceComputerProvider(
  language: Language,
  province: string
): string | undefined {
  return (
    provincesComputerProviders[language][province] ??
    provincesComputerProviders[language === 'en' ? 'fr' : 'en'][province]
  );
}
