import { Language } from './typings/Language';

export const mask: Array<RegExp | string> = [
  /\w/,
  /\w/,
  ' ',
  /\w/,
  /\w/,
  ' ',
  /\w/,
  /\w/,
  ' ',
  /\w/,
  /\w/,
];

export const postalCodeMask: Array<RegExp | string> = [
  /\w/,
  /\w/,
  /\w/,
  ' ',
  /\w/,
  /\w/,
  /\w/,
];

export const provincesShortForm: string[] = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
];

export const provincePostalPrefixes: { [key: string]: string[] } = {
  AB: ['T'],
  BC: ['V'],
  MB: ['R'],
  NB: ['E'],
  NL: ['A'],
  NS: ['B'],
  NT: ['X'],
  NU: ['X'],
  ON: ['K', 'L', 'M', 'N', 'P'],
  PE: ['C'],
  QC: ['G', 'H', 'J'],
  SK: ['S'],
  YT: ['Y'],
};

export const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

export const provincesComputerProvidersEnglish: { [key: string]: string } = {
  AB: 'https://cfsalberta.ca/cf.php',
  BC: 'http://reusetechbc.ca/connecting-families',
  // MB: 'https://www.c4smb.ca/cfi', //not working
  NB: 'https://cfsnb.ca/connecting-families/',
  NL: 'https://www.computersforschoolsnl.ca/connecting-families/',
  ON: 'https://rcto.ca/initiatives/',
  PE: 'https://www.princeedwardisland.ca/en/service/request-a-computer',
  SK: 'https://cfs-sask.ca/connecting-families/',
  YT: 'https://cfsy.ca/cfi/',
};

export const provincesComputerProvidersFrench: { [key: string]: string } = {
  NB: 'https://cfsnb.ca/familles-branchees/',
  QC: 'https://www.opeq.qc.ca/familles-branchees/',
};

export const provincesComputerProviders: {
  [language in Language]: { [province: string]: string };
} = {
  en: provincesComputerProvidersEnglish,
  fr: provincesComputerProvidersFrench,
};
