import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

export function trackAnalyticsEvent(args: UaEventOptions) {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.event(args);
  }
}

export function trackAnalyticsPageView(path: string) {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
}

export function setUserId(userId: string) {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.set({ userId });
  }
}
