import * as React from 'react';
import { TFunction } from 'i18next';
import { Container, Divider, Message, Loader, Form } from '../../semantic';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IAppState, IRequestState, INotifyRequestState } from '../../reducers';
import { push, replace } from 'connected-react-router';
import get from 'lodash/get';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { BackButton, Button } from '../common';
import {
  IIsp,
  IAddress,
  IFreeComputerInfo,
  notifyUsers,
  saveApplication,
  orderFreeComputer,
  IOrderFreeComputerAPIResponse,
} from '../../actions/api';
import {
  addAccessibilityMessage,
  setContactInfo,
  setFreeComputerInfo,
  IContactInfo,
  addDisabledComputerProvince,
} from '../../actions/app';
import { ContactForm } from '../ContactForm';
import { ComputerForm } from '../ComputerForm';
import { Modal } from '../Modal';
import { HeadTitle } from '../HeadTitle';
import { Heading } from '../Heading';
import { ISPCard } from '../ISPCard';
import { ComputerCard } from '../ComputerCard';
import { getAllTranslations } from '../../util';
import { ComputerAddressConfirmationForm } from '../ComputerAddressConfirmationForm';
import { getProvinceComputerProvider } from '../../util/getProvinceComputerProvider';

import './Finish.css';
import { Language } from '../../typings/Language';

function getAddressString(t: TFunction, address?: IAddress) {
  if (address === undefined) {
    return '';
  }

  const { line1, line2, city, province, postalCode } = address;
  const lineString = line2 ? `${line1}, ${line2},` : `${line1},`;
  const provinceString = t(`provinces.${province}`);
  return `${lineString} ${city} ${provinceString} ${postalCode}`;
}

interface IOwnState {
  contactModalIsOpen: boolean;
  hasInvalidEmail: boolean;
  hasInvalidPhone: boolean;
}

interface IStateProps {
  pin?: string;
  highlightedIsps: IIsp[];
  alternativeIsps?: IIsp[];
  serviceAddress?: IAddress;
  contactInfo?: IContactInfo;
  freeComputerInfo?: IFreeComputerInfo;
  locale: Language;
  isFreeComputerAvailable: boolean;
  ispAvailable: boolean;
  canOrderFreeComputer: boolean;
  saveApplicationRequest: IRequestState;
  notifyUserRequest: INotifyRequestState;
  orderFreeComputerRequest: IRequestState;
  priority: boolean;
  currentIsp?: IIsp;
  sufficientState: boolean;
}

interface IDispatchProps {
  push: typeof push;
  replace: typeof replace;
  saveApplication: typeof saveApplication;
  addDisabledComputerProvince: typeof addDisabledComputerProvince;
  addAccessibilityMessage: typeof addAccessibilityMessage;
  setContactInfo: typeof setContactInfo;
  notifyUsers: typeof notifyUsers;
  setFreeComputerInfo: typeof setFreeComputerInfo;
  orderFreeComputer: typeof orderFreeComputer;
}

type Props = IStateProps &
  IDispatchProps &
  WithNamespaces &
  RouteComponentProps<{}>;

class Component extends React.Component<Props, IOwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contactModalIsOpen: false,
      hasInvalidEmail: false,
      hasInvalidPhone: false,
    };
  }
  public componentDidMount() {
    const { sufficientState, ispAvailable, t } = this.props;

    if (!sufficientState) {
      return this.props.push('/');
    }

    if (!ispAvailable) {
      return this.props.replace(t('paths.noIsps'));
    }

    const promise = new Promise((resolve, reject) => {
      this.props.saveApplication({ resolve, reject });
    });

    promise.catch((err) => {
      return this.props.replace(t('paths.applicationError'));
    });

    this.props.addAccessibilityMessage(this.props.t('aria.messages.finish'));

    if (!this.props.notifyUserRequest.valid && this.props.contactInfo) {
      this.props.notifyUsers();
    }

    const finishPathExp = new RegExp(
      `^/(?:${getAllTranslations('paths.finish').join(
        '|'
      )})(?:/${getAllTranslations('paths.noInventory').join('|')})?$`
    );

    // If you have already ordered a free computer or if computers are not
    // available, redirect back to the finish page to close the modal. Always
    // allow the no-inventory screen to render to handle errors when submitting
    // orders.
    setTimeout(() => {
      if (
        !finishPathExp.test(this.props.location.pathname) &&
        (!this.props.canOrderFreeComputer ||
          !this.props.isFreeComputerAvailable)
      ) {
        this.props.replace(
          `/${this.props.locale}/${this.props.t('paths.finish')}`
        );
      }
    }, 0);

    return;
  }

  public render() {
    const {
      t,
      highlightedIsps,
      alternativeIsps,
      pin,
      freeComputerInfo,
      orderFreeComputerRequest,
      canOrderFreeComputer,
      contactInfo,
      saveApplicationRequest,
      priority,
      serviceAddress,
      currentIsp,
      locale,
      location,
    } = this.props;

    const computerPathExp = new RegExp(
      `^/(?:${getAllTranslations('paths.finish').join(
        '|'
      )})/(?:${getAllTranslations('paths.computerAddressEntry')
        .concat(getAllTranslations('paths.computerAddressConfirmation'))
        .join('|')})`
    );

    const noInventoryPathExp = new RegExp(
      `^/(?:${getAllTranslations('paths.finish').join(
        '|'
      )})/(?:${getAllTranslations('paths.noInventory').join('|')})`
    );

    // Show computer modal if we are on the correct route. If free computers
    // are not available, we redirect back to the finish route in
    // componentDidMount(). We also use the same logic to toggle the modal to
    // prevent a flash of rendering the modal followed by a redirect. The
    // no-inventory screen is always allowed to be displayed.
    const computerModalIsOpen =
      (computerPathExp.test(location.pathname) &&
        this.props.canOrderFreeComputer &&
        this.props.isFreeComputerAvailable) ||
      noInventoryPathExp.test(location.pathname);

    const req = saveApplicationRequest;
    if (
      !computerModalIsOpen &&
      !this.state.contactModalIsOpen &&
      (req.loading || (!req.valid && !req.invalid && !req.error))
    ) {
      return <Loader inline="centered" active={true} />;
    }

    const addressString = getAddressString(t, serviceAddress);

    const provinceComputerProvider = serviceAddress?.province
      ? getProvinceComputerProvider(locale, serviceAddress.province)
      : undefined;

    return (
      <Container text={true}>
        <HeadTitle title={t('screens.finish.title')} />
        <BackButton />
        <div>
          <Heading>
            {highlightedIsps.length === 1
              ? t(
                  `screens.finish.${
                    currentIsp
                      ? 'singleHighlightedIsp'
                      : 'singleHighlightedIspNoProvider'
                  }`,
                  {
                    name: highlightedIsps[0].name,
                  }
                )
              : t('screens.finish.multipleHighlightedIsps')}
          </Heading>

          <p>{t('screens.finish.paragraph1')}</p>
          <ul>
            <li>
              {t('screens.finish.listItem1')} <strong>{pin}</strong>
            </li>
            <li>
              {t('screens.finish.listItem2')} <strong>{addressString}</strong> (
              <Link to={t('paths.serviceAddressEntry')}>
                {t('screens.finish.changeAddress')}
              </Link>
              )
            </li>
          </ul>
          <p>{t('screens.finish.paragraph2')}</p>
          <ul>
            <li>
              <Trans i18nKey="screens.finish.twentyDollarInternet">
                Maximum price of <strong>$20 per month</strong> plus tax for a
                minimum download speed of <strong>50 Mbps</strong>
              </Trans>
            </li>
          </ul>
          <p>{t('general.or')}</p>
          <ul>
            <li>
              <Trans i18nKey="screens.finish.tenDollarInternet">
                Maximum price of <strong>$10 per month</strong> plus tax for{' '}
                <strong>10 Mbps</strong>.
              </Trans>
            </li>
          </ul>
          <p>{t('screens.finish.pleaseWriteDown')}</p>
          <p>
            <Trans i18nKey="screens.finish.tips">
              <strong>TIP:</strong> You can take a picture of the letter and
              email it to yourself. You can store it in a safe place with other
              valuables.
            </Trans>
          </p>
        </div>
        {highlightedIsps &&
          highlightedIsps.map((isp: IIsp) => {
            return (
              <ISPCard
                key={isp.id}
                isp={isp}
                locale={locale}
                province={serviceAddress?.province || ''}
              />
            );
          })}
        {!contactInfo || (!contactInfo.email && !contactInfo.phone) ? (
          <React.Fragment>
            <Container textAlign="center">
              <p>{t('screens.finish.ifYouHaveEmail')}</p>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ contactModalIsOpen: true });
                }}
              >
                <strong>{t('screens.finish.sendMeThisInfo')}</strong>
              </Button>
            </Container>
          </React.Fragment>
        ) : (
          this.getSentInfo()
        )}
        <Divider />
        {alternativeIsps && alternativeIsps.length > 0 && (
          <React.Fragment>
            <p>{t('screens.finish.youMayAlsoContact')}</p>
            {alternativeIsps.map((isp: IIsp) => {
              return (
                <ISPCard
                  key={isp.id}
                  isp={isp}
                  locale={locale}
                  province={serviceAddress?.province || ''}
                />
              );
            })}
            <Divider />
          </React.Fragment>
        )}
        <p className="isp-policies">
          <small>
            <Trans i18nKey="screens.finish.paragraph3">
              Your contract with an Internet Service Provider contains important
              elements, including critical information about data overage
              charges. For this and more information, please visit the{' '}
              <a
                href={t('links.connectingFamiliesFaq')}
                target="_blank"
                rel="noreferrer"
              >
                Government of Canada’s Connecting Families website
              </a>
              .
            </Trans>
          </small>
        </p>
        <Divider />
        <p>
          <Trans i18nKey="screens.finish.closer.paragraph1">
            <strong>Thank You</strong>
          </Trans>
        </p>
        <p>{t('screens.finish.closer.paragraph2')}</p>
        {saveApplicationRequest.invalid ? (
          <Message error={true} aria-live="polite" role="alert">
            {t('screens.finish.failSaveApplication')}
          </Message>
        ) : null}
        {provinceComputerProvider && (
          <ComputerCard locale={locale} url={provinceComputerProvider} />
        )}
        <Modal
          isOpen={this.state.contactModalIsOpen}
          onRequestClose={() => this.setState({ contactModalIsOpen: false })}
          closeButtonText={t('buttons.close')}
          ariaHideApp={false}
        >
          <Container text={true}>
            <ContactForm
              initialValues={{
                confirmEmail: get(this.props, 'contactInfo.confirmEmail', ''),
                email: get(this.props, 'contactInfo.email', ''),
                phone: get(this.props, 'contactInfo.phone', ''),
              }}
              onSubmit={async (values) => {
                this.props.addAccessibilityMessage(
                  this.props.t('aria.messages.finishContactInfoSubmit')
                );
                this.props.setContactInfo(values);
                try {
                  const notifyUsers = new Promise((resolve, reject) => {
                    this.props.notifyUsers({ resolve, reject });
                  });
                  const saveApplication = new Promise((resolve, reject) => {
                    this.props.saveApplication({ resolve, reject });
                  });
                  await Promise.all([notifyUsers, saveApplication]); // Fix for indiviual promises never resolving
                } finally {
                  this.setState({ contactModalIsOpen: false });
                }
              }}
              submitButtonText={this.props.t('screens.finish.send')}
              requireInput={true}
              confirmEmailError={this.props.t('formValidation.emailMatch')}
            />
          </Container>
        </Modal>
        <Modal
          isOpen={computerModalIsOpen}
          onRequestClose={() => {
            this.props.push(`/${t('paths.finish')}`);
          }}
          closeButtonText={t('buttons.close')}
          ariaHideApp={false} // Added to fix connect error until package is updated
        >
          <Switch>
            <Route
              exact={true}
              path={`/(${getAllTranslations('paths.finish').join(
                '|'
              )})/(${getAllTranslations('paths.computerAddressEntry').join(
                '|'
              )})`}
            >
              <Container text={true}>
                <ComputerForm
                  initialValues={{
                    city: get(
                      this.props,
                      'freeComputerInfo.city',
                      get(this.props, 'serviceAddress.city', '')
                    ),
                    confirmEmail: get(
                      this.props,
                      'contactInfo.confirmEmail',
                      ''
                    ),
                    email: get(
                      this.props,
                      'freeComputerInfo.email',
                      get(this.props, 'contactInfo.email', '')
                    ),
                    firstName: get(
                      this.props,
                      'freeComputerInfo.firstName',
                      ''
                    ),
                    lastName: get(this.props, 'freeComputerInfo.lastName', ''),
                    line1: get(
                      this.props,
                      'freeComputerInfo.line1',
                      get(this.props, 'serviceAddress.line1', '')
                    ),
                    line2: get(
                      this.props,
                      'freeComputerInfo.line2',
                      get(this.props, 'serviceAddress.line2', '')
                    ),
                    locale: get(
                      this.props,
                      'freeComputerInfo.locale',
                      get(this.props, 'locale', 'en')
                    ),
                    phone: get(
                      this.props,
                      'freeComputerInfo.phone',
                      get(this.props, 'contactInfo.phone', '')
                    ),
                    postalCode: get(
                      this.props,
                      'freeComputerInfo.postalCode',
                      get(this.props, 'serviceAddress.postalCode', '')
                    ),
                    province: get(
                      this.props,
                      'freeComputerInfo.province',
                      get(this.props, 'serviceAddress.province', '')
                    ),
                  }}
                  onSubmit={(values, actions) => {
                    if (!orderFreeComputerRequest.loading) {
                      this.props.addAccessibilityMessage(
                        t('aria.messages.computerInfoEntrySubmit')
                      );
                      this.props.setFreeComputerInfo(values);
                      actions.setSubmitting(false);
                      this.setState({
                        hasInvalidEmail: false,
                        hasInvalidPhone: false,
                      });
                      this.props.push(
                        `/${t('paths.finish')}/${t(
                          'paths.computerAddressConfirmation'
                        )}`
                      );
                    }
                  }}
                  submitButtonText={t('buttons.continue')}
                  isLoading={orderFreeComputerRequest.loading}
                  serviceAddress={serviceAddress}
                />
              </Container>
            </Route>
            <Route
              exact={true}
              path={`/(${getAllTranslations('paths.finish').join(
                '|'
              )})/(${getAllTranslations(
                'paths.computerAddressConfirmation'
              ).join('|')})`}
            >
              <Container text={true}>
                <ComputerAddressConfirmationForm
                  onSubmit={async (_, actions) => {
                    if (
                      freeComputerInfo &&
                      canOrderFreeComputer &&
                      !orderFreeComputerRequest.loading
                    ) {
                      this.props.addAccessibilityMessage(
                        t('aria.messages.computerAddressConfirmationSubmit')
                      );

                      this.setState({
                        hasInvalidEmail: false,
                        hasInvalidPhone: false,
                      });

                      const {
                        city,
                        email,
                        firstName,
                        lastName,
                        line1,
                        line2,
                        locale: freeComputerLocale,
                        phone,
                        postalCode,
                        province,
                      } = freeComputerInfo;

                      // On finish page, we need to update the application with
                      // the computer address before placing the computer order.
                      // If this is omitted, an order will be successfully
                      // placed in Shopify but our database will not have a
                      // record of the computer address.
                      //
                      // We don't do this in the priority computer order flow
                      // because we may not have enough information to save
                      // the application before we get to the finish page.
                      //
                      // We update the application before ordering the computer
                      // because a successful order will navigate to the finish
                      // page before the call to update the application can be
                      // made.
                      await new Promise((resolve, reject) => {
                        this.props.saveApplication({ resolve, reject });
                      });

                      const res = (await new Promise((resolve, reject) => {
                        this.props.orderFreeComputer(
                          {
                            city,
                            firstName,
                            lastName,
                            line1,
                            line2,
                            postalCode,
                            province,
                          },
                          priority,
                          freeComputerLocale,
                          email,
                          phone,
                          { resolve, reject }
                        );
                      })) as IOrderFreeComputerAPIResponse;

                      if (res.code === 'invalid' && res.errors) {
                        if (res.errors.includes('email')) {
                          this.setState({ hasInvalidEmail: true });
                        }
                        if (res.errors.includes('phone')) {
                          this.setState({ hasInvalidPhone: true });
                        }
                        if (res.errors.includes('inventory')) {
                          this.props.addDisabledComputerProvince(province);
                          this.props.push(
                            `/${t('paths.finish')}/${t('paths.noInventory')}`
                          );
                        }

                        // If we got an error, set form state submitting to
                        // false. If we succeeded, the redux-observable epic
                        // will trigger a redirect and we won't be able to
                        // update the form state for an unmounted form.
                        actions.setSubmitting(false);
                      }
                    }
                  }}
                  freeComputerInfo={freeComputerInfo}
                  addressEditPath={`/${t('paths.finish')}/${t(
                    'paths.computerAddressEntry'
                  )}`}
                  hasInvalidPhone={this.state.hasInvalidPhone}
                  hasInvalidEmail={this.state.hasInvalidEmail}
                  isLoading={orderFreeComputerRequest.loading}
                  push={this.props.push}
                />
              </Container>
            </Route>
            <Route
              exact={true}
              path={`/(${getAllTranslations('paths.finish').join(
                '|'
              )})/(${getAllTranslations('paths.noInventory').join('|')})`}
            >
              <Container text={true}>
                <Form>
                  <Heading>{t('screens.noInventory.heading')}</Heading>
                  <p>{t('screens.noInventory.paragraph')}</p>
                  <Button
                    type="button"
                    onClick={() => {
                      this.props.push(`/${t('paths.finish')}`);
                    }}
                  >
                    {t('buttons.continue')}
                  </Button>
                </Form>
              </Container>
            </Route>
          </Switch>
        </Modal>
      </Container>
    );
  }

  private getSentInfo() {
    const makeSuccessMessage = (email: boolean, phoneNumber: boolean) => {
      let successString = 'screens.finish.infoHasBeenSentPhone';
      if (email && phoneNumber) {
        successString = 'screens.finish.infoHasBeenSent';
      } else if (email) {
        successString = 'screens.finish.infoHasBeenSentEmail';
      }
      return (
        <div className="finish-padded">
          <p>{this.props.t(successString)}</p>
        </div>
      );
    };

    const makeErrorMessage = (translation: string) => (
      <Message
        error={true}
        aria-live="polite"
        content={this.props.t(translation)}
        role="alert"
      />
    );
    if (this.props.notifyUserRequest.valid && this.props.contactInfo) {
      return makeSuccessMessage(
        this.props.contactInfo.email !== undefined,
        this.props.contactInfo.phone !== undefined
      );
    }
    if (
      !this.props.notifyUserRequest.phone &&
      !this.props.notifyUserRequest.email
    ) {
      return makeErrorMessage('errors.notifyUserFail');
    } else if (this.props.notifyUserRequest.email) {
      return (
        <React.Fragment>
          {this.props.contactInfo && this.props.contactInfo.email
            ? makeSuccessMessage(true, false)
            : null}
          {this.props.contactInfo && this.props.contactInfo.phone
            ? makeErrorMessage('errors.notifyUserPhoneFail')
            : null}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.props.contactInfo && this.props.contactInfo.phone
          ? makeSuccessMessage(false, true)
          : null}
        {this.props.contactInfo && this.props.contactInfo.email
          ? makeErrorMessage('errors.notifyUserEmailFail')
          : null}
      </React.Fragment>
    );
  }
}

const hasSufficientState = (state: IAppState): boolean => {
  return Boolean(state.pin && state.mailingAddress && state.serviceAddress);
};

const mapStateToProps = (state: IAppState): IStateProps => {
  const isShowAll = (i: IIsp) => i.displayType === 'show-all';
  const currentIsp = state.availableIsps.find(
    (i: IIsp) => i.id === state.provider && i.displayType !== 'hide'
  );
  const highlightedIsps = currentIsp
    ? [currentIsp]
    : state.availableIsps.filter(isShowAll);
  const alternativeIsps = currentIsp
    ? state.availableIsps
        .filter(isShowAll)
        .filter((i: IIsp) => i.id !== state.provider)
    : undefined;

  const ispAvailable = currentIsp
    ? true
    : Boolean(state.availableIsps.find(isShowAll));

  const formattedPin = state.pin && state.pin.replace(/(.{2})/g, '$1 ').trim();

  const province = state.serviceAddress ? state.serviceAddress.province : '';
  const isFreeComputerAvailable =
    state.isFreeComputerAvailable &&
    state.canOrderFreeNonPriorityComputer &&
    !state.disabledComputerProvinces.includes(province);

  return {
    alternativeIsps,
    canOrderFreeComputer: state.canOrderFreeComputer,
    contactInfo: state.contactInfo,
    currentIsp,
    freeComputerInfo: state.freeComputerInfo,
    highlightedIsps,
    isFreeComputerAvailable,
    ispAvailable,
    locale: state.locale,
    notifyUserRequest: state.notifyUserRequest,
    orderFreeComputerRequest: state.orderFreeComputerRequest,
    pin: formattedPin,
    priority: !state.providerInternetService,
    saveApplicationRequest: state.saveApplicationRequest,
    serviceAddress: state.serviceAddress,
    sufficientState: hasSufficientState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return bindActionCreators(
    {
      addAccessibilityMessage,
      addDisabledComputerProvince,
      notifyUsers,
      orderFreeComputer,
      push,
      replace,
      saveApplication,
      setContactInfo,
      setFreeComputerInfo,
    },
    dispatch
  );
};

export const Finish = connect<IStateProps, IDispatchProps, {}, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withNamespaces()(Component)));
